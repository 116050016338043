html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  min-height: 100%;
}

#root {
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
}

#app-wrapper {
  flex: 1;
  height: 100%;
}

.app-container {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 100%;

  height: 100%;
  overflow: auto;
  overflow-x: hidden;
}

/* Cross platform text-security https://github.com/noppa/text-security */
@font-face {
  font-family: 'text-security-disc';
  src: url('./fonts/text-security-disc.eot');
  src: url('./fonts/text-security-disc.eot?#iefix') format('embedded-opentype'),
    url('./fonts/text-security-disc.woff') format('woff'),
    url('./fonts/text-security-disc.ttf') format('truetype'),
    url('./fonts/text-security-disc.svg#text-security') format('svg');
}
